import React, { Component } from 'react';
import Web3 from 'web3';
import './PresaleDistribution.css';
import { contractAddress, contractAbi } from '../../configs/vestStreamContract';
import ProgressBar from '../ProgressBar/ProgressBar';
import Header from '../Header/Header';

class PresaleDistribution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalAvailable: 0,
      totalAmount: 0,
      available: 0,
      claimed: 0,
      claimedPercentage: 0,
      availablePercentage: 0,
      account: '',
      contract: null,
      selectedClaim: null,
      claims: []
    };
  }

  async componentDidMount() {
    window.connectWallet = this.connectToBlockchain;
  }

  connectToBlockchain = async () => {

    // CONNECT TO METAMASK
    if (window.ethereum) {
      await window.ethereum.enable();
      const web3 = new Web3(Web3.givenProvider || 'http://localhost:8545');
      const accounts = await web3.eth.getAccounts();
      this.setState({ account: accounts[0] });
      window.account = this.state.account;
      console.log(window.account);

      // INITIALIZE CONTRACT
      const contract = new web3.eth.Contract(contractAbi, contractAddress);
      this.setState({ contract });

      // Retrieve data about the user's claim.
      const claim = await this.state.contract.methods.getClaim(this.state.account).call();
      this.setState({ selectedClaim: claim });
      await this.getClaimData(this.state.account);
    } else {
      window.alert('Please install MetaMask');
    }
  };

  getClaimData = async (beneficiary) => {
    const claim = await this.state.contract.methods.getClaim(beneficiary).call();
    const totalAmount = parseInt(claim.totalAmount) / 1000000000000000000;
    const claimed = parseInt(claim.amountClaimed) / 1000000000000000000;
    const available = parseInt(await this.state.contract.methods.claimableAmount(beneficiary).call()) / 1000000000000000000;
    const totalAvailable = claimed + available;
    const claimedPercentage = (claimed / totalAmount) * 100;
    const availablePercentage = (totalAvailable / totalAmount) * 100;
    console.log(claimedPercentage, availablePercentage)
    this.setState(
      {
        totalAvailable,
        totalAmount,
        available,
        claimed,
        claimedPercentage,
        availablePercentage
      },
      () => {
        console.log(this.state);
      }
    );
  };

  claimSuper = async () => {
    try {
      await this.state.contract.methods
        .claim(this.state.account)
        .send({ from: this.state.account })
        .once('receipt', (receipt) => {
          return receipt;
        });
      this.getClaimData(this.state.account);
    } catch (error) {
      console.log(error);
    }
  };

  formatNumber = (value, decimals = 2) => {
    value = value.toString().split('.');
    value[0] = value[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + ',');
    if (value[1]) {
      value[1] = value[1].substring(0, decimals);
      value[1] = value[1].padEnd(decimals, '0');
    } else {
      value[1] = ''.padEnd(decimals, '0');
    }
    value = value.join('.');
    if (decimals === 0) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  };

  formatPercentage = (value) => {
    return value > 0 && value < 5 ? 5 : value;
  };

  render() {
    return (
      <div>
        <Header account={this.state.account} />
        <div className='PD-main'>
          {/* HEADER */}
          <div className='PD-header'>Super Presale Distribution Claim</div>
          {/* PROGRESS BAR */}
          <div className='PD-progress-container'>
            <div className='PD-progress-full'>
              <div className='PD-progress-available-bar' style={{ width: `${this.formatPercentage(this.state.availablePercentage)}%` }} />
              <div className='PD-progress-claimed-bar' style={{ width: `${this.formatPercentage(this.state.claimedPercentage)}%` }} />
            </div>
          </div>
          {/* RELEASED INFO */}
          <div className='PD-released-container'>
            <div className='claim-currency'>
              <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'>
                <defs></defs>
                <path
                  fill='#000'
                  fillRule='evenodd'
                  d='M7.11 14.1c-1.93.47-3.03.74-3.87 1.9-.13-.5-.47-2 .02-2.96.34-.68 1.2-1.27 2.58-1.75 1.08-.39 2.48-.7 3.95-.88l.51-.06-.5-.11c-2.36-.5-3.94-1.02-5.55-2.35a2.7 2.7 0 01-.89-2.6c.15-.74.76-2.08 3.15-2.78 1.05-.31 1.98-.54 2.8-.73l.04-.01c2-.48 3.25-.78 3.89-1.77.02.5-.03 1.63-.84 2.74C11.3 4.22 9.3 5.15 6.44 5.51l-.51.06.5.1c2.36.5 3.94 1.03 5.55 2.35a2.7 2.7 0 01.89 2.6c-.15.75-.76 2.09-3.14 2.79-1.03.3-1.87.5-2.62.69zm4.7-7.08a9.87 9.87 0 00-2.72-1.36 7.48 7.48 0 003.66-2.24c.1 1 .02 2.46-.94 3.6z'
                  clipRule='evenodd'
                ></path>
              </svg>
            </div>
            <div className='PD-released-totalAvailable'>{this.formatNumber(this.state.totalAvailable)}</div>
            <div className='PD-released-totalAmount'>/{this.state.totalAmount} SUPER TOTAL</div>
          </div>
          {/* AVAILABLE - CLAIMED */}
          <div className='PD-available-claimed-container'>
            <ProgressBar label={'Available'} current={this.state.available} total={this.state.totalAmount - this.state.claimed} />
            <ProgressBar label={'Claimed'} current={this.state.claimed} total={this.state.totalAmount} />
          </div>
          <div className='PD-claimBttn-container'>
            <div className='PD-claimBttn noselect' onClick={this.claimSuper}>
              Claim SUPER
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PresaleDistribution;
