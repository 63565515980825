import './App.css';
// import NFTAirdrop from "./components/NFTAirdrop/NFTAirdrop";
import PresaleDistribution from './components/PresaleDistribution/PresaleDistribution';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';

function App() {
  return (
    <div className='App'>
      <div className='content'>
        <PresaleDistribution />
      </div>
      <Footer />
    </div>
  );
}

export default App;
