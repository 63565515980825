import React, { Component } from 'react';
import './ProgressBar.css';

class ProgressBar extends Component {
  calcWidth = () => {
    console.log(this.props.current, this.props.total)
    const total = this.props.total === 0 ? 1 : this.props.total;
    return {
      width: `${Math.floor((this.props.current / total) * 100)}%`,
      backgroundImage: this.props.label === 'Available' ? 'linear-gradient(90deg, #7112ff, #005eff)' : 'linear-gradient(90deg, #ff7112, #e6a400)'
    };
  };

  formatNumber = (value, decimals = 2) => {
    value = value.toString().split('.');
    value[0] = value[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + ',');
    if (value[1]) {
      value[1] = value[1].substring(0, decimals);
      value[1] = value[1].padEnd(decimals, '0');
    } else {
      value[1] = ''.padEnd(decimals, '0');
    }
    value = value.join('.');
    if (decimals === 0) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  };

  render() {
    return (
      <div>
        <div className='available'>
          {this.props.label}: <strong>{this.formatNumber(this.props.current)} SUPER</strong>
        </div>
        <div className='mini-chart-bg'>
          <div style={this.calcWidth()} className='mini-available-bar'></div>
        </div>
      </div>
    );
  }
}

export default ProgressBar;
